import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import styles from "../styles/index.module.css"
import { Helmet } from "react-helmet"
import { Container } from "@material-ui/core"
export default function datenschutz() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Philipp Schulz Consulting</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="icon"
          type="image/png"
          href="https://firebasestorage.googleapis.com/v0/b/ps-c-b11af.appspot.com/o/Favicon-PSc-32x32px.png?alt=media&token=af2df22c-7858-4b26-b434-3793e62de831"
          sizes="16x16"
        />
      </Helmet>
      <div>
        <Navbar />
        <Container maxWidth="md" className={styles.container}>
          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "22px",
              fontFamily: '"Open Sans",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
              color: "#bb9273",
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                Der digitale Umbruch macht vor dem Einzelhandel nicht halt
              </span>
            </strong>
          </p>
          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "20px",
              fontFamily: '"Calibri",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "20px",
              fontFamily: '"Calibri",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              Die Digitale Disruption ist über unsere verschiedenen
              Lebensbereiche hereingebrochen und hat uns in unterschiedlicher
              Geschwindigkeit zum Umdenken gezwungen. Manche Veränderungen kamen
              für Konsumenten leichter und schneller als erwartet, man nehme
              beispielsweise die Nutzung von Smartphones und modernen Messaging
              Systemen, die ja enorme Auswirkungen auf die Jugendsprache, das
              Sozial- und Kommunikationsverhalten haben.
            </span>
          </p>

          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "20px",
              fontFamily: '"Calibri",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              Manche Wirtschaftsbereiche haben in der Digitalisierung und der
              damit ermöglichten Automatisierung oder Transparenz ein
              innovatives Potential erkannt, das den Vorsprung eines
              Unternehmens gegen die Konkurrenz wieder eine Zeitlang abzusichern
              vermag. So geschehen in den kompetitiveren Industrien. Waren es
              früher die Fachkräfte in den jeweiligen Berufen, die den Vorsprung
              von Regionen und Unternehmen determinierten, sind es heute (auch)
              die Facharbeiter in den Digitalisierungsberufen, beziehungsweise
              traditionell ausgebildete Fachkräfte mit starkem Fokus auf die
              neuen Technologien, die den Erfolg eines Unternehmens nachhaltig
              absichern. Einige Branchen haben sich zumindest eine zeitlang noch
              ohne allzu dramatische Auswirkungen weiterentwickeln können,
              richtig verschließen konnte sich dennoch fast niemand.
            </span>
          </p>

          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "20px",
              fontFamily: '"Calibri",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              Einer der am stärksten in Bedrängnis geratenen Wirtschaftszweige
              ist der traditionelle Handel. Konnte man in früheren Jahren mit
              der guten Beziehung zur Stammkundschaft und der großen
              Fachkenntnis der Verkäufer punkten, fordert die heutige Kundschaft
              gnadenlos einen Komfort der Auffindbarkeit des Angebots, wie man
              das eben nur von Amazon und Co. kennt. Zusätzlich nutzen viele der
              Digital Natives auch das Beratungsangebot des stationären Handels,
              um sich danach auf Schnäppchenjagd ins Internet zu begeben. Hier
              haben die Händler noch nichts entgegenzusetzen, es fehlen die
              digitalen Facharbeiter*innen und meistens auch die nötige
              Affinität für digitale Themen. Manche haben den Trend gesehen und
              sich selbst in die Onlinewelt gewagt, kleine Webshops mit
              unterschiedlichem Erfolg sind entstanden. Andere mussten
              angesichts der digitalen Konkurrenz ihr Angebot reduzieren oder
              gar zusperren. Alleine in Österreich ist auch dadurch die Zahl der
              Ladengeschäfte in den letzten 10 Jahren um etwa ein Viertel
              zurückgegangen während Amazon und Co. mittlerweile einen Anteil
              von über 10% des Einzelhandelsvolumens erreichen. Bereits 4,5mio
              Österreicher*innen haben zumindest einmal schon im Internet
              eingekauft, Tendenz steigend.
            </span>
          </p>

          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "20px",
              fontFamily: '"Calibri",sans-serif',
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              Zuwarten ist also keine Alternative und die Covid-19 Entwicklung
              hat gezeigt, dass ein schnelles Handeln angebracht ist. In
              Österreich gibt es heute zahlreiche Förderungen, die den
              Unternehmen die Chance ermöglichen, sich gründlich und sehr
              günstig mit der Materie zu befassen – auch und vor allem für
              KMU´s. Der Zeitpunkt ist also so gut wie noch nie: Lassen Sie sich
              beraten, wie Sie die neuen Technologien maßgeschneidert für Ihr
              Unternehmen und Ihre Situation nutzen können.
            </span>
          </p>
          <p
            style={{
              marginRight: "0cm",
              marginLeft: "0cm",
              fontSize: "22px",
              fontFamily: '"Calibri",sans-serif',
              color: "#bb9273",
              marginTop: "0cm",
              marginBottom: "8.0pt",
              lineHeight: "107%",
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                <a href="/contact">Vereinbaren Sie ein Erstgespräch!</a>
              </span>
            </strong>
          </p>
        </Container>

        <Footer />
      </div>
    </>
  )
}
